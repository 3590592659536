<script setup lang="ts">
import { onMounted } from 'vue';
import type { IApproval } from '@Models/JoinToAuction/approval.model';
import { fetchApproval } from '@Services/joinToAuction.service';

import DataElement from '@Components/dataset/DataElement.vue';
import DataElementHeader from '@Components/dataset/DataElementHeader.vue';
import TextBody2 from '@Typography/TextBody2.vue';
import TextSubTitle2 from '@Typography/TextSubTitle2.vue';

const props = defineProps({
  itemId: {
    required: true,
    type: Number
  }
});
const approval = ref<IApproval | null>(null);

onMounted(async () => {
  const response: any = await fetchApproval(`${props.itemId}`);
  if (response) {
    approval.value = response;
  }
});
</script>

<template>
  <DataElement>
    <DataElementHeader>
      <cds-row no-gutters align="center">
        <cds-col cols="12">
          <TextSubTitle2 class="font--bold">{{
            $t('statement')
          }}</TextSubTitle2>
        </cds-col>
      </cds-row>
    </DataElementHeader>
    <TextBody2 class="mt-sm">
      <span v-if="approval && approval.contents">{{ approval.contents }}</span>
      <span v-else>{{ $t('noStatement') }}.</span>
    </TextBody2>
  </DataElement>
</template>
