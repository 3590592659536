<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useDisplay } from 'vuetify';
import { storeToRefs } from 'pinia';

import tabsName from '@Enums/tabsName';
import registryUnitWorkflowStage from '@Enums/registryUnitWorkflowStage';

import { useUserAuthStore } from '~coreStores/user-auth.store';

import { useItemStore } from '@Store/item.module';
import { useNoteStore } from '@Store/note.module';
import { useComplaintStore } from '@Store/complaint.module';
import { useItemTabStore } from '@Store/itemTabs.module';
import { useELKRKRoute as useRouteComposable } from '@Composables/elkrk-route';
import { useToasts } from '@Composables/toast';
import { useBreadcrumbs } from '@Composables/breadcrumbs';
import { useColors } from '@Composables/color';

import ItemElement from '@Components/item/ItemElement.vue';
import LoaderDetails from '@Components/items-details/LoaderDetails.vue';
import UserItemDetailsNavigation from '@Components/items-details/user/UserItemDetailsNavigation.vue';
import TermsOfPayment from '@Components/items-details/user/termsOfPayment/TermsOfPayment.vue';
import ComplaintsList from '@Components/items-details/complaints/ComplaintsList.vue';
import NotesList from '@Components/items-details/notes/NotesList.vue';
import Breadcrumbs from '@Components/Breadcrumbs.vue';

definePageMeta({
  middleware: ['user-auth']
});

const activeTab = ref('');
const { $i18n, $routeNames } = useNuxtApp();
const route = useRoute();
const loading = ref(true);
const error = ref(false);

const userAuthStore = useUserAuthStore();
const { getBailiffOfficeUserData } = storeToRefs(userAuthStore);
const itemTabStore = useItemTabStore();
const itemStore = useItemStore();
const noteStore = useNoteStore();
const complaintStore = useComplaintStore();
const { routeItemId } = useRouteComposable();
const { setErrorToast } = useToasts();
const { primary } = useColors();
const { mdAndDown } = useDisplay();
const { setBidderBreadcrumbsInAuctionDetails } = useBreadcrumbs();

const item = computed(() => itemStore.getItem);
const userResigned = computed<boolean>(() => {
  return item.value && item.value.userContent.registryUnitStatus === registryUnitWorkflowStage.RESIGNED;
});

const tabs = computed(() => [
  {
    title: $i18n.t('userItemDetails.tabs.accession'),
    length: null,
    show: item.value.userContent.joinedIn,
    name: tabsName.JOINED_IN,
    value: 1,
    component: 'TermsOfPayment'
  },
  {
    title: $i18n.t('buttons.complaints'),
    length: null,
    show: item.value.userContent.joinedIn,
    name: tabsName.COMPLAINTS,
    value: 2,
    component: 'ComplaintsList'
  },
  {
    title: $i18n.t('userItemDetails.tabs.notes'),
    length: null,
    show: item.value.userContent.joinedIn,
    name: tabsName.NOTES,
    value: 3,
    component: 'NotesList'
  }
]);
const morphs = {
  TermsOfPayment,
  ComplaintsList,
  NotesList
};
const tabsToShow = computed(() => tabs.value.filter((tab) => tab.show));
const bailiff = computed(() => !!getBailiffOfficeUserData.value);

const manualBreadcrumbs = computed(() => setBidderBreadcrumbsInAuctionDetails(item.value));
const direction = computed(() => {
  return mdAndDown.value ? 'vertical' : 'horizontal';
});

onMounted(async () => {
  setActiveTab();
  try {
    const itemId = routeItemId(route);

    await itemStore.fetchItem(itemId);
    if (item.value?.userContent.joinedIn) {
      const noteData = {
        id: itemId,
        registryUnitId: item.value.userContent.bidderId
      };
      noteStore.fetchNotes(noteData);
      complaintStore.fetchComplaintsList(itemId);
    } else {
      item.value?.userContent.joinedIn
        ? setErrorToast($i18n.t('userItemDetails.resignFromItem.excludedInfo'))
        : setErrorToast($i18n.t('accessItemError'));
      redirect400();
    }
  } catch {
    setErrorToast($i18n.t('accessItemError'));
    redirect400();
  } finally {
    loading.value = false;
  }
});

function redirect400() {
  navigateTo({ name: $routeNames.error400 });
}

function setActiveTab() {
  activeTab.value = itemTabStore.getActiveTab || tabsName.JOINED_IN;
}
</script>

<template>
  <div v-if="loading">
    <LoaderDetails />
  </div>
  <div
    v-else
    class="user"
  >
    <cds-row
      v-if="bailiff || error"
      justify="center"
    >
      <cds-col
        cols="12"
        xl="10"
        lg="11"
      >
        {{ $t('userItemDetails.title') }}
      </cds-col>
    </cds-row>

    <div v-else>
      <cds-row justify="center">
        <cds-col
          cols="12"
          xl="10"
          lg="11"
          class="d-flex align-content-space-between"
        >
          <Breadcrumbs :default="manualBreadcrumbs" />
          <UserItemDetailsNavigation />
        </cds-col>
      </cds-row>

      <cds-row justify="center">
        <cds-col
          cols="12"
          xl="10"
          lg="11"
        >
          <ItemElement class="item__element" />
        </cds-col>
      </cds-row>

      <cds-row
        justify="center"
        v-if="userResigned"
      >
        <cds-col
          cols="12"
          xl="10"
          lg="11"
        >
          <cds-alert
            type="info"
            class="v-alert--dark-level-0"
          >
            {{ $t('userItemDetails.cancelJoinToAuction') }}
          </cds-alert>
        </cds-col>
      </cds-row>

      <cds-row
        justify="center"
        ref="complaints"
        class="tabs-container"
        v-if="tabsToShow && tabsToShow.length > 0"
      >
        <cds-col
          cols="12"
          xl="10"
          lg="11"
        >
          <CdsTabs
            :color="primary"
            v-model="activeTab"
            :direction="direction"
          >
            <CdsTab
              v-for="tab in tabsToShow"
              :key="tab.value"
              :value="tab.name"
              >{{ tab.title }}
            </CdsTab>
          </CdsTabs>

          <cds-window
            v-model="activeTab"
            class="mt-md"
          >
            <cds-window-item
              v-for="tab in tabsToShow"
              :value="tab.name"
            >
              <component :is="morphs[tab.component]" />
            </cds-window-item>
          </cds-window>
        </cds-col>
      </cds-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user {
  background-color: map-get($lightSurface, level1);
}
</style>
