<script setup lang="ts">
import config from '@/config';

const show = ref(true);
const emit = defineEmits<{
  hide: [];
}>();

const dialogMaxWidth = config.settings.dialog.smallMaxWidth;

function close() {
  emit('hide');
}
</script>

<template>
  <CdsPopup
    v-model="show"
    :max-width="dialogMaxWidth"
    size="regular"
    :title="$t('userItemDetails.resignFromItem.excludedTitle')"
    :blurred="true"
    @click:close="close"
  >
    <CdsText
      class="mb-sm"
      variant="normal-caption"
      color="mid-emphasis-on-surface"
    >
      {{ $t('userItemDetails.resignFromItem.excludedInfo') }}
    </CdsText>

    <div class="justify-end d-flex">
      <CdsBtn
        @click="close()"
        variant="outlined"
        type="primary"
        size="small"
      >
        {{ $t('buttons.close') }}
      </CdsBtn>
    </div>
  </CdsPopup>
</template>
