<template>
  <div>
    <cds-row justify="center">
      <cds-col cols="12" xl="10" lg="11">
        <cds-skeleton-loader type="card"></cds-skeleton-loader>
      </cds-col>
    </cds-row>

    <cds-row justify="center" class="tabs-container">
      <cds-col cols="12" xl="10" lg="11">
        <CdsTabs v-model="activeTab">
          <CdsTab
            v-for="n in 3"
            :key="n"
            cols="12"
            md="6"
            lg="4"
          >
            <cds-skeleton-loader
              height="240"
              type="card-avatar"
            />
          </CdsTab>
        </CdsTabs>
      </cds-col>
    </cds-row>
    <cds-row justify="center">
      <cds-col cols="12" xl="10" lg="11">
        <cds-skeleton-loader type="card"></cds-skeleton-loader>
      </cds-col>
    </cds-row>
  </div>
</template>

<script setup lang='ts'>
import { ref } from 'vue';

const activeTab = ref(0);
</script>
