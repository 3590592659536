<script setup lang="ts">
import Divider from '@Components/Divider.vue';
import dayjs from 'dayjs';
import { useItem } from '@Composables/item';
import { useNote } from '@Composables/note';
import type { IUserContent } from '@Models/Item/userContent.model';
import DataElement from '@Components/dataset/DataElement.vue';
import DataElementHeader from '@Components/dataset/DataElementHeader.vue';
import DataElementAttribute from '@Components/dataset/DataElementAttribute.vue';
import TextSubTitle2 from '@Typography/TextSubTitle2.vue';

defineProps({
  userContent: {
    required: true,
    type: Object as PropType<IUserContent>
  },
  itemId: {
    required: true,
    type: Number
  }
});

const { setCallLink } = useNote();
const { userStatus } = useItem();
</script>

<template>
  <DataElement>
    <DataElementHeader>
      <CdsRow
        no-gutters
        :align="'center'"
      >
        <CdsCol cols="6">
          <TextSubTitle2 class="font--bold">{{ $t('bidderStatus.yourLabel') }}</TextSubTitle2>
        </CdsCol>
      </CdsRow>
    </DataElementHeader>
    <div class="w-100">
      <DataElementAttribute
        keyValue="Status"
        :textValue="userStatus"
      />

      <DataElementAttribute
        :keyValue="$t('bidderStatus.dateOfJoining')"
        :textValue="dayjs(userContent.joinedInAt).format('DD.MM.YYYY HH:mm')"
      />
      <DataElementAttribute
        :keyValue="$t('bidderStatus.uniqueIdNumber')"
        :textValue="userContent.myBiddderUuid ? userContent.myBiddderUuid : '-'"
      />

      <div v-if="userContent.excluded">
        <Divider class="mt-sm" />
        <DataElementAttribute
          :keyValue="$t('statusBox.notification.title')"
          :textValue="setCallLink(itemId, userContent.exclusionMessageId, $t('buttons.refuseJoinIn'))"
          :appendIcon="true"
        />
        <DataElementAttribute
          :keyValue="$t('statusBox.notification.sendDate')"
          :textValue="dayjs(userContent.exclusionAt).format('DD.MM.YYYY HH:mm')"
        />
        <DataElementAttribute
          :keyValue="$t('statusBox.reason')"
          :textValue="userContent.exclusionReason ? userContent.exclusionReason : '-'"
        />
      </div>
    </div>
  </DataElement>
</template>
