<script setup lang="ts">
import { ref, computed, reactive, onMounted, defineAsyncComponent } from 'vue';
import { useUserAuthStore } from '~coreStores/user-auth.store';
import { storeToRefs } from 'pinia';
import { useItem } from '@Composables/item';
import { useComplaintStore } from '@Store/complaint.module';
import { fetchPrincipals } from '@Services/item.service';
import statusTypes from '@Enums/statusTypes';
import attachmentsTypes from '@Enums/attachmentsTypes';
import type { IPrincipalDetail } from '@Models/PrincipalHistory/principalDetail.model';
import type { IBidderUserData } from '~coreTypes/user-auth.model';
import type { IAttachment } from '@Models/Bidder/attachment.model';
import StatusBox from '@Components/items-details/user/termsOfPayment/StatusBox.vue';
import BidderStatement from '@Components/items-details/user/termsOfPayment/BidderStatement.vue';

const complaintStore = useComplaintStore();
const userAuthStore = useUserAuthStore();
const { item, auctionIsKnocked, auctionEnded, auctionNotEventuated, checkIsOrdinaryRealEstate, itemStore } = useItem();
const principalInfo = ref<any>(null);
let users = reactive<IPrincipalDetail[]>([]);
let registryUnitDocuments = reactive<IAttachment[]>([]);
let requestDocuments = reactive<IAttachment[]>([]);
const showUser = ref(false);
const showWaiver = ref(false);
const showBidderMarginSection = ref(false);
const route = useRoute();

const checkToShowRealEstatePurchaseSection = computed<boolean>(() => {
  return item.value.userContent.winner && checkIsOrdinaryRealEstate.value && auctionEnded.value;
});
const showPrincipalDocuments = computed(() => {
  if (showUser.value)
    return defineAsyncComponent(() => import('@Components/items-details/user/termsOfPayment/PrincipalDocuments.vue'));
});
const showAttachmentDocuments = computed(() => {
  if (showUser.value)
    return defineAsyncComponent(() => import('@Components/items-details/user/termsOfPayment/AttachmentDocuments.vue'));
});
const showPurchaseStatus = computed(() => {
  if (showUser.value)
    return defineAsyncComponent(() => import('@Components/items-details/user/termsOfPayment/PurchaseStatus.vue'));
});
const showBidderCurrentData = computed(() => {
  if (showUser.value)
    return defineAsyncComponent(() => import('@Components/items-details/user/termsOfPayment/BidderCurrentData.vue'));
});
const showPurchasePrice = computed(() => {
  if (showPurchaseSection.value && !checkToShowRealEstatePurchaseSection.value)
    return defineAsyncComponent(() => import('@Components/items-details/user/termsOfPayment/PurchasePrice.vue'));
});
const showBidderMargin = computed(() => {
  if (showBidderMarginSection.value && item.value.margin)
    return defineAsyncComponent(() => import('@Components/items-details/user/termsOfPayment/BidderMargin.vue'));
});
const showBidderBar = computed(() => {
  if (showWaiver.value && showPurchaseSection.value && !checkIsOrdinaryRealEstate.value)
    return defineAsyncComponent(() => import('@Components/items-details/user/termsOfPayment/BidderBar.vue'));
});
const showWaiverBidderSection = computed(() => {
  if (showWaiver.value && showPurchaseSection.value) {
    return defineAsyncComponent(() => import('@Components/items-details/user/termsOfPayment/WaiverBidderSection.vue'));
  }
});
const showRealEstatePurchaseSection = computed(() => {
  if (checkToShowRealEstatePurchaseSection.value)
    return defineAsyncComponent(
      () => import('@Components/items-details/user/termsOfPayment/PurchasePriceForOrdinaryRealEstate.vue')
    );
});

const isProxy = computed<boolean>(() => item.value.userContent.principalStatus !== statusTypes.NO_REQUEST);

const showPurchaseSection = computed<boolean>(() => {
  return item.value.userContent.winner && (auctionIsKnocked.value || auctionEnded.value || auctionNotEventuated.value);
});

onMounted(async () => {
  const response = await itemStore.fetchBidderById(item.value.id);
  if (isProxy.value) {
    const response = await fetchPrincipals(item.value.id);
    if (response) {
      users = response.principals;
      principalInfo.value = response;
      registryUnitDocuments = response.attachmentsInfo.filter((attachment: any) => {
        return attachment.category === attachmentsTypes.RU_DOCUMENT;
      });
      requestDocuments = response.attachmentsInfo.filter((attachment: any) => {
        return attachment.category !== attachmentsTypes.RU_DOCUMENT;
      });
    }
  }

  if (!(response as any)?.request?.status) showUser.value = true;

  if (item.value.userContent.winner) {
    const response = await complaintStore.fetchWaiverForBidder(item.value.id);
    showWaiver.value = response.object ? true : false;
  }

  const marginResponse = await itemStore.fetchMarginInfo(route.params.itemId);
  showBidderMarginSection.value = marginResponse.margin !== null;
});

function beforeDestroy() {
  complaintStore.$reset();
}
</script>

<template>
  <div>
    <component :is="showBidderBar" />
    <component
      :is="showWaiverBidderSection"
      :item="item"
      class="mb-md"
    />
    <component :is="showPurchasePrice" />
    <component :is="showRealEstatePurchaseSection" />
    <StatusBox
      :itemId="item.id"
      :userContent="item.userContent"
    />
    <component :is="showBidderMargin" />
    <div v-if="showUser">
      <component
        :is="showPrincipalDocuments"
        v-if="principalInfo"
        :principalInfo="principalInfo"
        :requestDocuments="requestDocuments"
        :registryUnitDocuments="registryUnitDocuments"
        :item="item"
      />
      <component
        :is="showAttachmentDocuments"
        :item="item"
      />
      <component
        :is="showPurchaseStatus"
        :users="users"
        :userContent="item.userContent"
      />
      <component
        :is="showBidderCurrentData"
        :bailiff="item.bailiff"
      />
    </div>
    <BidderStatement
      :itemId="item.id"
      class="mt-md"
    />
  </div>
</template>
