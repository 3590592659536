<template>
  <ListElement>
    <div class="item__content">
      <NuxtLinkLocale
        :to="{
          name: 'items-id-slug',
          params: { id: itemId, slug: slug }
        }"
        class="item__thumbnail"
      >
        <img
          :src="'data:image/png;base64,' + fileContentSmall"
          :alt="itemName"
        />
        <CdsChip
          v-if="secondAuctionType"
          variant="solid"
          type="default"
          size="xsmall"
          class="item__thumbnail__info"
        >
          {{ $t('secondItemText') }}
        </CdsChip>
      </NuxtLinkLocale>
      <div class="item__data">
        <div class="pb-sm">
          <NuxtLinkLocale
            :to="{
              name: 'items-id-slug',
              params: { id: itemId, slug: slug }
            }"
            class="text-body2 mb-sm color--light-level-3"
            >{{ itemName }}</NuxtLinkLocale
          >
        </div>

        <div
          class="item__attributes"
          v-if="!bailiffSlimItem"
        >
          <div class="item__attribute">{{ signature }}</div>
          <div class="item__attribute">{{ $t('item.id') }} {{ itemId }}</div>
          <div class="item__attribute">
            {{ $t('item.createdAt') }}
            {{ dateCreated }}{{ $t('item.year') }}
          </div>
        </div>
        <div
          class="item__attributes color--light-level-2"
          v-if="bailiffSlimItem"
        >
          <div class="item__attribute item__attribute--no-border">
            <CdsIcon
              icon="users"
              :small="true"
            />
            {{ $t('bailiffItemDetails.accessions') }}:
            {{ bailiffSlimItem.countOfBidders }}
          </div>
          <div class="item__attribute item__attribute--no-border">
            <CdsIcon
              icon="sad"
              :small="true"
            />
            {{ $t('buttons.complaints') }}:
            {{ bailiffSlimItem.countOfComplaints }}
          </div>
          <div class="item__attribute item__attribute--no-border">
            <CdsIcon
              icon="coin"
              :small="true"
            />
            {{ $t('item.warranty') }}:
            {{ setCurrency(bailiffSlimItem.margin) }}
          </div>
          <div class="item__attribute">
            <CdsIcon
              icon="clock"
              :small="true"
            />
            {{ translateAuctionStatus(bailiffSlimItem.auctionStatus) }}
            <span
              class="text-lowercase"
              v-if="extraStatus"
              >, {{ extraStatus }}</span
            >
          </div>
        </div>
        <div
          class="item__categories"
          v-if="!bailiffSlimItem"
        >
          <div class="item__category">{{ translateMainCategory(auctionCategory) }},&nbsp;</div>
          <div class="item__category">
            {{ itemCategory }}
          </div>
        </div>
        <div
          class="item__border"
          v-if="!bailiffSlimItem"
        ></div>
        <div
          v-if="bailiffSlimItem"
          class="pt-3"
        >
          <TextCaption :mediumDark="true">
            {{ $t('item.callPrice') }}
          </TextCaption>
          <TextSubTitle1 class="font-weight-bold">{{ setCurrency(bailiffSlimItem.openingValue) }}</TextSubTitle1>
        </div>
      </div>
    </div>
    <div class="item__additional">
      <div
        class="item__prices"
        v-if="!bailiffSlimItem"
      >
        <TextSubTitle1>{{ setCurrency(itemPrice) }}</TextSubTitle1>
        <TextCaption>
          {{ $t('item.warranty') }}: <span v-if="itemMargin">{{ setCurrency(itemMargin) }}</span
          ><span v-else-if="requestingForLowerMargin">{{ $t('item.requestingForLowerMargin') }}</span>
          <span v-else-if="item.userContent.loweredMarginStatus === lowerMargin.LOWERED">
            <span v-if="itemMargin">{{ $t('item.marginLoweredPartial') }}</span>
            <span v-else>{{ $t('item.noMarginPaymentWay') }}</span>
          </span>
          <span v-else>{{ $t('item.noRequired') }}</span>
        </TextCaption>
      </div>
      <div
        class="item__status"
        :class="{ 'item__attribute--no-border': bailiffSlimItem }"
      >
        <CdsChip
          v-if="!bailiffSlimItem"
          id="test--auctionStatus"
          variant="solid"
          type="default"
          size="small">
          {{ translateAuctionStatus(auctionStatus) }}
          <span class="text-lowercase" v-if="extraStatus"
          >, {{ extraStatus }}</span
          >
        </CdsChip>
      </div>
    </div>
  </ListElement>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useItemStore } from '@Store/item.module';
import { useDate } from '@Composables/date';
import { useHelpers } from '@Composables/helpers';
import marginPaymentWays from '@Enums/marginPaymentWays';
import lowerMargin from '@Enums/lowerMargin';
import auctionTypes from '@Enums/auctionTypes';
import statusTypes from '@Enums/statusTypes';
import { EAuctionStatus } from '@Enums/auctionStatus.model';
import auctionKinds from '@Enums/auctionKinds';
import auctionCategories from '@Enums/auctionCategories';

import TextCaption from '@Typography/TextCaption.vue';
import ListElement from '@Components/ListElement.vue';
import TextSubTitle1 from '@Typography/TextSubTitle1.vue';

const { slugify, translateAuctionStatus, setCurrency, translateMainCategory } = useHelpers();
const { formatDate } = useDate();
const itemStore = useItemStore();

const { $i18n } = useNuxtApp();

const bailiffSlimItem = computed(() => {
  return itemStore.getBailiffSlimItem;
});

const item = computed(() => itemStore.getItem);

const secondAuctionType = computed(() => {
  return bailiffSlimItem.value?.auctionType === auctionTypes.SECOND;
});

const waiverIsRequested = computed(() => {
  return item.value.userContent.waiverStatus === statusTypes.REQUESTED;
});

const auctionCategory = computed(() => {
  return bailiffSlimItem.value?.mainCategory ? bailiffSlimItem.value?.mainCategory : item.value.auctionCategory;
});

const itemCategory = computed(() => {
  return bailiffSlimItem.value?.itemCategory || item.value.itemCategory.value;
});

const fileContentSmall = computed(() => {
  return bailiffSlimItem.value?.contentSmallBase64
    ? bailiffSlimItem.value?.contentSmallBase64
    : item.value.attachments.find((attachment) => {
        return attachment.defAttach;
      })?.fileContentSmall;
});

const itemId = computed(() => {
  return bailiffSlimItem.value?.itemId ? bailiffSlimItem.value?.itemId : item.value.id;
});

const signature = computed(() => {
  return bailiffSlimItem.value?.signature ? bailiffSlimItem.value?.signature : item.value.signature;
});

const dateCreated = computed(() => {
  return bailiffSlimItem.value?.dateCreated ? bailiffSlimItem.value?.dateCreated : formatDate(item.value.dateCreated);
});

const itemName = computed(() => {
  return bailiffSlimItem.value?.name ? bailiffSlimItem.value?.name : item.value.name;
});

const itemPrice = computed(() => {
  return bailiffSlimItem.value?.maxBid ? bailiffSlimItem.value?.maxBid : item.value.openingValue;
});

const itemMargin = computed(() => {
  return bailiffSlimItem.value?.margin ? bailiffSlimItem.value?.margin : item.value.margin;
});

const auctionStatus = computed(() => {
  return bailiffSlimItem.value?.auctionStatus ? bailiffSlimItem.value?.auctionStatus : item.value.auctionStatus;
});

const auctionNotEventuated = computed(() => {
  return item.value.notEventuated;
});

const auctionIsKnocked = computed(() => {
  return item.value.auctionStatus === EAuctionStatus.KNOCKED;
});

const auctionEnded = computed(() => {
  return item.value.auctionStatus === EAuctionStatus.CLOSED;
});
const auctionRemitToCourt = computed(() => {
  return item.value.auctionStatus === EAuctionStatus.REMIT_TO_COURT;
})

const extraStatus = computed(() => {
  if (auctionNotEventuated.value) {
    return setNotEventuatedStatus();
  } else {
    return setEndedStatus();
  }
});

const setNotEventuatedStatus = () => {
  if (item.value.systemFailure) {
    return  $i18n.t('item.status.systemFailure');
  } else if (auctionIsKnocked.value && waiverIsRequested.value) {
    return  $i18n.t('item.status.waiverStatusNotDecided');
  } else if (auctionEnded.value && (item.value.closeInfo.knockDownRefused || item.value.closeInfo.knockedDownRefuse)) {
    return  $i18n.t('item.status.knockDownRefused');
  } else if (item.value.closeInfo.itemPaymentRefused) {
    return  $i18n.t('item.status.paymentRefused');
  } else if (item.value.closeInfo.noBidders) {
    return  $i18n.t('item.status.noJoinIn');
  } else if (item.value.closeInfo.noBids) {
    return  $i18n.t('item.status.noBids');
  } else if (item.value.closeInfo.waiverConfirmed) {
    return  $i18n.t('item.status.waiverStatusConfirmed');
  } else if (item.value.closeInfo.complaintJustified) {
    return  $i18n.t('item.status.complaintJustified');
  }
};

const setEndedStatus = () => {
  if (auctionIsKnocked.value && waiverIsRequested.value) {
    return  $i18n.t('item.status.waiverStatusNotDecided');
  } else if (auctionIsKnocked.value && item.value.userContent.waiverStatus != statusTypes.NOT_DECIDED) {
    return setKnockedStatus();
  } else if (auctionIsKnocked.value) {
    return  $i18n.t('item.status.knockedDown');
  } else if (auctionRemitToCourt.value) {
    return  $i18n.t('item.status.deliveredToCourt');
  } else if (!auctionNotEventuated.value && checkIsOrdinaryRealEstate.value && auctionEnded.value) {
    if (checkIsOrdinaryRealEstate.value) {
      return  $i18n.t('item.status.awaitingForCourt');
    } else {
      return  $i18n.t('item.status.awaitingTheArrival');
    }
  } else if (!auctionNotEventuated.value && !checkIsOrdinaryRealEstate.value && auctionEnded.value) {
    return  $i18n.t('item.status.awaitingTheArrival');
  } else if (item.value.closeInfo.waiverConfirmed) {
    return  $i18n.t('item.status.waiverStatusConfirmed');
  } else if (item.value.closeInfo.complaintJustified) {
    return  $i18n.t('item.status.complaintJustified');
  } else if (item.value.userContent.waiverStatus == statusTypes.NOT_DECIDED) {
    return  $i18n.t('item.status.waiverStatusNotDecided');
  }
};

const setKnockedStatus = () => {
  if (item.value.closeInfo.knockDownAt) {
    return  $i18n.t('item.status.knockedDown');
  } else {
    return  $i18n.t('item.status.knockedDown');
  }
};

const requestingForLowerMargin = computed(() => {
  return (
    item.value.userContent.marginPaymentWay === marginPaymentWays.LOWERED &&
    !(
      item.value.userContent.loweredMarginStatus === lowerMargin.LOWERED ||
      item.value.userContent.loweredMarginStatus === lowerMargin.LOWEREDTOZERO
    )
  );
});

const slug = computed(() => {
  return slugify(itemName.value);
});

const checkIsOrdinaryRealEstate = computed(() => {
  return (
    item.value.auctionKind === auctionKinds.ORDINARY && item.value.auctionCategory === auctionCategories.REAL_ESTATE
  );
});
</script>

<style lang="scss" scoped>
@import '@Assets/scss/components/item/item-element.scss';
</style>
