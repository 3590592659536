<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import dayjs from 'dayjs';

import { useUserAuthStore } from '~coreStores/user-auth.store';

import { useItemStore } from '@Store/item.module';
import type { IItem } from '@Models/Item/item.model';
import type { INote } from '@Models/Note/note.model';

import AvatarComponent from '@Components/AvatarComponent.vue';
import NoteTitle from '@Components/items-details/notes/NoteTitle.vue';

const itemStore = useItemStore();
const userAuthStore = useUserAuthStore();
const { getBailiffOfficeUserData } = storeToRefs(userAuthStore);

defineProps({
  note: {
    required: true,
    type: Object as PropType<INote>
  }
});

const router = useRouter();
const { $routeNames } = useNuxtApp();
const item = computed<IItem>(() => {
  return itemStore.getItem;
});

const isBailiff = computed(() => !!getBailiffOfficeUserData.value);

function goToNoteMailbox(id: number): void {
  const name = isBailiff.value
    ? $routeNames.bailiff.itemNote(item.value.id, id)
    : $routeNames.user.itemNote(item.value.id, id);

  router.push({
    name: name,
    params: { itemId: item.value.id, noteId: id }
  });
}

function checkNoteHasBeenRead(note: INote): boolean {
  return note.readDate === null || (note.responsesUnreadCount >= 1 && note.responsesCount != 0);
}

function setName(note: INote): string {
  return isBailiff.value ? note.userName : note.bailiffName;
}
</script>

<template>
  <div
    class="element d-flex flex-direction-row"
    @click="goToNoteMailbox(note.bidderMessageId)"
  >
    <div class="details__box--width">
      <AvatarComponent :text="setName(note)" />
    </div>
    <div class="w-100">
      <p class="mb-0 sender font-weight-bold">{{ setName(note) }}</p>
      <NoteTitle
        :title="note.requestType"
        :class="{
          'font-weight-bold': checkNoteHasBeenRead(note)
        }"
      />
      <p class="mb-0 details">{{ $t('item.id') }} {{ item.id }} | {{ item.name }}</p>
    </div>
    <div class="details__box--width details__box">
      <CdsIcon
        icon="undo"
        class="icon"
        v-if="note.responsesCount > 0"
      />
      <p class="details mb-0">{{ dayjs(note.dateCreated).format('DD.MM.YYYY') }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@Assets/scss/components/item-details/notes/single-note.scss';
</style>
