<script setup lang="ts">
import config from '@/config';
import { useItem } from '@Composables/item';
import { useToasts } from '@Composables/toast';
import { useHelpers } from '@Composables/helpers';
import { resignationFromJoiningAuction } from '@Services/item.service';

const emit = defineEmits<{
  hide: [closeModal: boolean];
}>();

const loading = ref(false);
const show = ref(true);

const { slugify } = useHelpers();
const { item } = useItem();
const { $i18n, $routeNames } = useNuxtApp();
const { setSuccessToast } = useToasts();

const resignNumber = computed(() => {
  return item.value.userContent.resignationCount + 1 === 1 ? $i18n.t('first') : $i18n.t('second');
});

const dialogMaxWidth = config.settings.dialog.smallMaxWidth;

function close() {
  emit('hide', false);
}

async function onSubmit() {
  loading.value = true;

  await resignationFromJoiningAuction(item.value.id)
    .then(async () => {
      setSuccessToast($i18n.t('userItemDetails.resignFromItem.successMessage'));

      navigateTo({
        name: $routeNames.item(item.value.id, slugify(item.value.name)),
        params: {
          id: item.value.id,
          slug: slugify(item.value.name)
        }
      });
    })
    .catch(() => {})
    .finally(() => {
      close();
      loading.value = false;
    });
}
</script>

<template>
  <CdsPopup
    v-model="show"
    :max-width="dialogMaxWidth"
    size="regular"
    :title="$t('userItemDetails.resignFromItem.header')"
    :blurred="true"
    @click:close="close"
  >
    <CdsForm @submit.prevent="onSubmit">
      <CdsText
        class="mb-sm"
        variant="normal-caption"
        color="mid-emphasis-on-surface"
      >
        {{ $t('userItemDetails.resignFromItem.subheader') }}
      </CdsText>

      <CdsText
        class="mb-sm"
        variant="normal-caption"
        color="mid-emphasis-on-surface"
      >
        {{ $t('userItemDetails.resignFromItem.body') }}
      </CdsText>
      <CdsText
        class="mb-sm"
        variant="normal-caption"
        color="mid-emphasis-on-surface"
      >
        {{ $t('userItemDetails.resignFromItem.maxResignInfo') }}
        {{ $t('userItemDetails.resignFromItem.quitInfo') }}
        {{ resignNumber }}
        {{ $t('userItemDetails.resignFromItem.withAccession') }}.
      </CdsText>
      <div class="justify-end d-flex">
        <CdsBtn
          @click="close()"
          class="mr-sm"
          variant="text"
          type="primary"
          size="regular"
        >
          {{ $t('no') }}
        </CdsBtn>
        <CdsBtn
          :loading="loading"
          button-type="submit"
          variant="solid"
          type="primary"
          size="regular"
        >
          {{ $t('buttons.resignFromItem') }}
        </CdsBtn>
      </div>
    </CdsForm>
  </CdsPopup>
</template>
