<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useNoteStore } from '@Store/note.module';

import type { INote } from '@Models/Note/note.model';
import { useItem } from '@Composables/item';
import SingleNote from '@Components/items-details/notes/SingleNote.vue';
import SkeletonLoaderForTabs from '@Components/items-details/SkeletonLoaderForTabs.vue';

const loader = ref<boolean>(true);
const { routeItemId } = useItem();

const noteStore = useNoteStore();
const notes = computed<INote[]>(() => {
  return noteStore.getNotes;
});

onMounted(async () => {
  const data = {
    id: routeItemId.value
  };
  const response = await noteStore.fetchNotes(data);
  if (response) loader.value = false;
});
</script>

<template>
  <section>
    <div v-if="loader">
      <SkeletonLoaderForTabs />
    </div>
    <div v-else>
      <div v-if="notes.length >= 1">
        <SingleNote
          :note="note"
          v-for="(note, index) in notes"
          :key="index"
          class="cursor--pointer"
        />
      </div>
      <div v-else>
        <div class="complaint-empty">
          <div class="complaint-empty__icon cursor--pointer">
            <CdsIcon icon="note" />
          </div>
          <div class="complaint-empty__header">
            {{ $t('notes.message') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@Assets/scss/components/complaint/complaints-empty.scss';
</style>
