<script setup lang="ts">
import { ref, computed } from 'vue';

import { useItemStore } from '@Store/item.module';
import { EAuctionStatus } from '@Enums/auctionStatus.model';
import type { IItem } from '@Models/Item/item.model';
import { useHelpers } from '@Composables/helpers';
import ResignFromItem from '@Components/items-details/user/ResignFromItem.vue';
import ResignFromItemInfo from '@Components/items-details/user/ResignFromItemInfo.vue';
import ResignFromItemExcluded from '@Components/items-details/user/ResignFromItemExcluded.vue';

const { slugify } = useHelpers();
const showResignFromItemInfo = ref(false);
const itemStore = useItemStore();

const item = computed<IItem>(() => itemStore.getItem);

const canResign = computed<boolean>(() => {
  return (
    (item.value.auctionStatus === EAuctionStatus.CREATED || item.value.auctionStatus === EAuctionStatus.USED) &&
    item.value.userContent.joinedIn
  );
});

const mayResign = computed<boolean>(() => {
  return (
    (item.value.auctionStatus === EAuctionStatus.CREATED || item.value.auctionStatus === EAuctionStatus.USED) &&
    item.value.userContent.joinedIn &&
    item.value.userContent.registryUnitStatus !== EAuctionStatus.RESIGNED
  );
});
</script>

<template>
  <cds-row
    align="center"
    class="navigation"
  >
    <cds-col cols="12">
      <div>
        <CdsBtn
          size="regular"
          variant="outlined"
          class="mr-sm"
          :to="{
            name: $routeNames.item(item.id, slugify(item.name)),
            params: {
              id: item.id,
              slug: slugify(item.name)
            }
          }"
          type="primary"
        >
          {{ $t('buttons.goToEbidding') }}
        </CdsBtn>

        <CdsMenu :offset="5">
          <template #activator="{ props }">
            <CdsBtn
              variant="outlined"
              type="primary"
              size="regular"
              v-bind="props"
            >
              <CdsIcon icon="more_h" />
            </CdsBtn>
          </template>
          <template #default>
            <CdsMenuList size="regular">
              <CdsMenuListItem
                @click="showResignFromItemInfo = true"
                :disabled="!canResign"
              >
                {{ $t('buttons.resignFromJoining') }}
              </CdsMenuListItem>
            </CdsMenuList>
          </template>
        </CdsMenu>
      </div>
      <div v-if="mayResign && showResignFromItemInfo">
        <ResignFromItemExcluded
          v-if="item.userContent.excluded"
          @hide="showResignFromItemInfo = false"
        />
        <ResignFromItem
          v-else-if="item.userContent.resignationCount < 2"
          @hide="showResignFromItemInfo = false"
        />
        <ResignFromItemInfo
          v-else
          @hide="showResignFromItemInfo = false"
        />
      </div>
    </cds-col>
  </cds-row>
</template>

<style scoped lang="scss">
@import '@Assets/scss/components/item-details/navigation.scss';
</style>
